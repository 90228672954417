import React from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Advert from '../components/Advert'

import './WhatIsVATScreen.css'

const WhatIsVATScreen = () => {
  const imageDir      = `/img`
  const hmrcLink      = 'https://www.gov.uk/government/organisations/hm-revenue-customs'
  const hmrcInstagram = 'https://www.instagram.com/hmrcgovuk/'

  return (
    <>
      <Helmet>
        <title>Understanding UK VAT: An Introduction</title>
        <meta
          name="description"
          content="Learn UK VAT basics including definition, input and output tax, calculation methods, and HMRC enforcement. A comprehensive guide to UK VAT for small businesses."
        />
      </Helmet>

      <Header />
      <main className="py-3">
        <Container id="WhatIsVAT">
          <Row>
            <Col>
              <Advert />
            </Col>

            <Col xs={8}>
              <h1 className="display-4">Understanding UK VAT: An Introduction</h1>

              <p style={{ marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em' }}>
                <img src={`${imageDir}/what-is-vat.jpg`} alt="What is VAT?" />
              </p>

              {/* Author Box */}
              <section id="author-info">
                <p>By Sharon Gillespie</p>
                <p>Last Updated: February 10, 2025</p>
              </section>

              {/* Table of Contents */}
              <section id="table-of-contents">
                <h2>Table of Contents</h2>
                <ul>
                  <li><a href="#definition">Definition of VAT</a></li>
                  <li><a href="#input-output">VAT Input and Output Tax</a></li>
                  <li><a href="#indirect-tax">VAT as an Indirect Tax</a></li>
                  <li><a href="#calculating-vat">Calculating VAT</a></li>
                  <li><a href="#vat-purchases">VAT on Purchases</a></li>
                  <li><a href="#vat-sales">VAT on Sales</a></li>
                  <li><a href="#net-liability">Net VAT Liability</a></li>
                  <li><a href="#uk-revenue">UK Annual Gross VAT Revenue</a></li>
                  <li><a href="#vat-origin">The VAT Origin Story</a></li>
                  <li><a href="#hmrc">HMRC&apos;s Role</a></li>
                  <li><a href="#hmrc-web">The HMRC Website &amp; Social Media</a></li>
                  <li><a href="#key-takeaways">Key Takeaways</a></li>
                </ul>
              </section>

              {/* Definition Section */}
              <section id="definition">
                <h3>A Definition for Value Added Tax (VAT)</h3>
                <p>
                  <strong>
                    VAT (Value Added Tax) is a sales tax levied on goods and services in the UK. As a cornerstone of VAT accounting, it is applied at different rates—20% for most items, 5% for some goods, and 0% for exempt items—according to rules established by HM Revenue &amp; Customs (HMRC). This introductory guide is designed to help you understand the fundamentals of VAT and its importance for UK businesses.
                  </strong>
                </p>
              </section>

              {/* VAT Input and Output Tax Section */}
              <section id="input-output">
                <h3>VAT Input Tax and VAT Output Tax</h3>
                <p>
                  VAT registered businesses in the UK are required to charge VAT on their sales (known as VAT output tax) and pay VAT on their purchases (known as VAT input tax). The difference between these amounts determines a business&apos;s net VAT liability—whether they owe money to HMRC or are entitled to a refund.
                </p>
                <p>
                  Understanding these concepts is essential for effective VAT accounting and for ensuring that your business complies with UK VAT regulations.
                </p>
              </section>

              {/* VAT as an Indirect Tax Section */}
              <section id="indirect-tax">
                <h3>VAT is an Indirect Tax</h3>
                <p>
                  Unlike direct taxes such as income tax, VAT is an indirect tax. This means that while businesses collect the tax from customers, the final burden of the tax is borne by the end consumer. For small businesses in the UK, knowing how VAT works is crucial because it affects pricing, cash flow, and overall profitability.
                </p>
              </section>

              {/* Calculating VAT Section */}
              <section id="calculating-vat">
                <h3>Calculating VAT</h3>
                <p>
                  Calculating VAT involves determining the amount of tax to be added to sales (output tax) and the amount recoverable on purchases (input tax). Whether you are using manual VAT calculation formulas or leveraging an online VAT calculator, accuracy in these calculations is essential for compliance and financial planning.
                </p>
                <p>
                  For further details on manual VAT calculation formulas, check out our <Link to="/uk-vat-formula">VAT Formula UK</Link> page.
                </p>
              </section>

              {/* VAT on Purchases Section */}
              <section id="vat-purchases">
                <h4>VAT on Purchases</h4>
                <p>
                  To calculate VAT on purchases, first identify the total value of taxable purchases. This includes all goods and services subject to the standard (20%), reduced (5%), or zero rates. Then, multiply the taxable amount by the corresponding VAT rate to find the VAT payable on purchases.
                </p>
                <h5>A Quick Example for VAT on Purchases</h5>
                <div className="example">
                  <p>
                    For example, for taxable purchases of £100,000 at a standard VAT rate of 20%, the VAT liability is calculated as:
                  </p>
                  <p>
                    <b>100,000 x 0.2 = 20,000</b>
                  </p>
                  <p>
                    <i>This shows that 20% of £100,000 equals a VAT charge of £20,000.</i>
                  </p>
                </div>
              </section>

              {/* VAT on Sales Section */}
              <section id="vat-sales">
                <h4>VAT on Sales</h4>
                <p>
                  Similarly, calculating VAT on sales involves determining the total taxable value of sales and then applying the appropriate VAT rate. This yields the VAT output tax that your business should collect from its customers.
                </p>
                <h5>A Quick Example for VAT on Sales</h5>
                <div className="example">
                  <p>
                    For taxable sales of £120,000 at the standard VAT rate of 20%, the VAT liability is:
                  </p>
                  <p>
                    <b>120,000 x 0.2 = 24,000</b>
                  </p>
                  <p>
                    <i>Thus, 20% of £120,000 equals a VAT charge of £24,000.</i>
                  </p>
                </div>
              </section>

              {/* Net Liability Section */}
              <section id="net-liability">
                <h4>Net VAT Liability</h4>
                <p>
                  After computing the VAT on both purchases and sales, your business must determine its net VAT liability. This is done by subtracting the VAT input tax from the VAT output tax. If the output tax is greater than the input tax, the difference is payable to HMRC. Conversely, if the input tax exceeds the output tax, you may be eligible for a VAT refund.
                </p>
                <p>
                  Keeping precise records and using reliable tools such as an online VAT calculator can streamline this process, reducing errors and ensuring compliance.
                </p>
              </section>

              {/* UK Annual Gross VAT Revenue Section */}
              <section id="uk-revenue">
                <h3>The UK's Annual Gross VAT Revenue</h3>
                <p>
                  VAT is a major revenue stream for the UK government, second only to income tax. Over the past decade, the UK has consistently collected significant revenue from VAT. For example, between 2010 and 2020, the annual gross VAT revenue often exceeded 100 billion GBP.
                </p>
                <p>
                  This steady flow of revenue underscores the importance of VAT for public finances and highlights the critical role VAT plays in the UK economy.
                </p>
                <p style={{ marginLeft: '2.5em', marginTop: '1.5em', marginBottom: '1.5em', marginRight: '1em' }}>
                  <img src={`${imageDir}/gross_uk_vat_revenue.JPG`} alt="UK Gross Annual VAT Revenue for the period 2010 - 2020" />
                </p>
                <Table striped bordered hover className="table table-sm">
                  <thead>
                    <tr className="table-primary">
                      <th>Year</th>
                      <th>Gross VAT Revenue (Billions)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2010</td>
                      <td>101.286</td>
                    </tr>
                    <tr>
                      <td>2011</td>
                      <td>114.204</td>
                    </tr>
                    <tr>
                      <td>2012</td>
                      <td>117.312</td>
                    </tr>
                    <tr>
                      <td>2013</td>
                      <td>123.592</td>
                    </tr>
                    <tr>
                      <td>2014</td>
                      <td>128.316</td>
                    </tr>
                    <tr>
                      <td>2015</td>
                      <td>133.953</td>
                    </tr>
                    <tr>
                      <td>2016</td>
                      <td>139.483</td>
                    </tr>
                    <tr>
                      <td>2017</td>
                      <td>143.646</td>
                    </tr>
                    <tr>
                      <td>2018</td>
                      <td>151.803</td>
                    </tr>
                    <tr>
                      <td>2019</td>
                      <td>153.723</td>
                    </tr>
                    <tr>
                      <td>2020</td>
                      <td>137.657</td>
                    </tr>
                  </tbody>
                </Table>
                <p>
                  Source: <a href="https://www.ons.gov.uk/economy/governmentpublicsectorandtaxes/publicsectorfinance/datasets/countryandregionalpublicsectorfinancesrevenuetables">Office of National Statistics, Revenue Tables</a>
                </p>
                <p>
                  The drop in VAT revenue during 2020 reflects the economic challenges posed by the COVID-19 pandemic, which led to reduced consumer spending.
                </p>
              </section>

              {/* VAT Origin Story Section */}
              <section id="vat-origin">
                <h3>The VAT Origin Story</h3>
                <p>
                  Introduced in France in 1954 as a means to rebuild after World War II, VAT has since become one of the most widely used consumption taxes in the world. The United Kingdom adopted VAT in 1973, replacing the Purchase Tax upon joining the European Economic Community. Today, VAT is a critical part of the UK tax system, ensuring revenue is collected efficiently at every stage of production and distribution.
                </p>
                <p>
                  This historical perspective not only demonstrates the evolution of VAT but also underscores its continued importance in modern tax systems.
                </p>
              </section>

              {/* HMRC Section */}
              <section id="hmrc">
                <h3>HMRC&apos;s Role in VAT Enforcement</h3>
                <p>
                  HM Revenue &amp; Customs (HMRC) is responsible for administering and enforcing VAT in the UK. Businesses must ensure they correctly calculate and report their VAT liabilities to avoid fines and penalties. Effective VAT accounting, combined with a sound understanding of VAT principles, helps maintain a good compliance record and fosters trust between businesses and HMRC.
                </p>
              </section>

              {/* HMRC Website Section */}
              <section id="hmrc-web">
                <h3>The HMRC Website and Social Media</h3>
                <p>
                  For further details on VAT, including the latest updates and guidelines, visit the <a href={hmrcLink}>HMRC website</a>. HMRC also maintains an active presence on social media platforms such as <a href={hmrcInstagram}>Instagram</a> to engage with the public and ensure transparency.
                </p>
              </section>

              {/* Key Takeaways Section */}
              <section id="key-takeaways">
                <h2>Key Takeaways</h2>
                <ul>
                  <li><strong>VAT Definition:</strong> VAT is a sales tax on goods and services with varying rates in the UK.</li>
                  <li><strong>Input vs Output Tax:</strong> Businesses must manage VAT on purchases and sales to determine their net liability.</li>
                  <li><strong>Indirect Tax:</strong> Although collected by businesses, the final tax burden falls on the consumer.</li>
                  <li><strong>Calculation Methods:</strong> Accurate VAT calculations are crucial for compliance, whether done manually or with an online VAT calculator.</li>
                  <li><strong>Government Revenue:</strong> VAT is a major revenue source for the UK government, underpinning public finances.</li>
                  <li><strong>HMRC Enforcement:</strong> Adhering to HMRC guidelines is key for effective VAT accounting.</li>
                </ul>
              </section>
            </Col>

            <Col>
              <Advert />
            </Col>
          </Row>

          <Row>
            <Col style={{ height: '4em' }}></Col>
          </Row>
          <Row>
            <Col>
              <Advert />
            </Col>
          </Row>
          <Row>
            <Col style={{ height: '4em' }}></Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default WhatIsVATScreen
