import React from 'react'
import { Helmet } from 'react-helmet'
import { HashLink } from 'react-router-hash-link'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Advert from '../components/Advert'

import './CurrentVATRateScreen.css'

const CurrentVATRateScreen = () => {
    const imageDir = `/img`

    return (
        <>
            <Helmet>
                <title>An In-Depth Guide to UK VAT Rates: Standard, Reduced, and Zero</title>
                <meta
                    name="description"
                    content="Explore the intricacies of UK VAT rates—including the 20% standard rate, 5% reduced rate, and 0% zero-rated VAT. Understand VAT exemptions and stay compliant with the latest UK tax legislation."
                />
            </Helmet>

            <Header />
            <main>
                <div id="CurrentVATRateScreen" className="copy wrapper">
                    <div className="layout-row">
                        <div className="layout-col advert-col">
                            <Advert />
                        </div>

                        <div className="layout-col content-col">
                            <section id="introduction">
                                <h1 className="display-4">A Guide to UK VAT Rates: Standard, Reduced, and Zero</h1>

                                <p className="image-container">
                                    <img src={`${imageDir}/current-vat-rate.jpg`} alt="A Guide to UK VAT Rates" />
                                </p>

                                {/* Author Box */}
                                <div className="author-box">
                                    <p>Written by Sharon Gillespie</p>
                                    <p>Last updated: 12 Feb 2025</p>
                                </div>

                                <h2>Introduction</h2>
                                <p>
                                    Every UK business must comprehend its tax obligations—especially with respect to Value Added Tax (VAT). VAT is a consumption tax levied on goods and services, and understanding its various rates is essential for regulatory compliance.
                                </p>
                                <p>
                                    If you're new to the subject, we encourage you to read our&nbsp;
                                    <HashLink to="/what-is-vat">What is VAT</HashLink>&nbsp;page for a foundational explanation. Additionally, for those needing quick calculations, our&nbsp;
                                    <HashLink to="/vat-calculator">VAT Calculator</HashLink>&nbsp;tool is available.
                                </p>
                                <p>
                                    This guide provides a detailed examination of the three main VAT rates applied in the UK, ensuring that businesses are well-equipped to manage their tax responsibilities.
                                </p>

                                <h3>In this Article</h3>
                                <ul>
                                    <li><HashLink smooth to='#introduction'>Introduction</HashLink></li>
                                    <li><HashLink smooth to='#standard-rate-of-vat'>The Standard Rate of VAT</HashLink></li>
                                    <li><HashLink smooth to='#reduced-rate-of-vat'>The Reduced Rate of VAT</HashLink></li>
                                    <li><HashLink smooth to='#zero-rated-vat'>Zero-Rated VAT</HashLink></li>
                                    <li><HashLink smooth to='#vat-exemptions'>VAT Exemptions</HashLink></li>
                                    <li><HashLink smooth to='#rates-comparison-table'>Comparison of VAT Rated Goods &amp; Services</HashLink></li>
                                    <li><HashLink smooth to='#changes-to-vat-rates'>Changes to VAT Rates</HashLink></li>
                                    <li><HashLink smooth to='#key-takeaways'>Key Takeaways</HashLink></li>
                                    <li><HashLink smooth to='#conclusion'>Conclusion</HashLink></li>
                                </ul>
                            </section>

                            <section id="snippet" description="Overview of the three main VAT rates">
                                <h2>The United Kingdom's Three Main VAT Rates</h2>
                                <p>
                                    The United Kingdom employs three principal VAT rates:
                                </p>
                                <p>
                                    <strong>The standard rate is 20%, complemented by a reduced rate of 5% and a zero-rated category at 0%.</strong>
                                </p>
                                <p>
                                    The following sections provide an in-depth examination of each VAT rate and their applications.
                                </p>
                            </section>

                            <section id="standard-rate-of-vat">
                                <h2>The Standard Rate of VAT (20%)</h2>
                                <p>
                                    The standard VAT rate of 20% applies to the majority of goods and services. This rate is widely recognized and forms the basis for most VAT calculations.
                                </p>
                                <p>
                                    Examples of items subject to the standard rate include:
                                </p>
                                <ul>
                                    <li>Electronics, appliances, and home furnishings</li>
                                    <li>Non-essential food and beverages, including alcoholic drinks and confectionery</li>
                                </ul>
                            </section>

                            <section id="reduced-rate-of-vat">
                                <h2>The Reduced Rate of VAT (5%)</h2>
                                <p>
                                    The reduced rate of 5% applies to certain goods and services that are deemed essential or provide significant social benefits.
                                </p>
                                <p>
                                    Typical items eligible for the reduced rate include:
                                </p>
                                <ul>
                                    <li>Child car seats</li>
                                    <li>Mobility aids for the elderly</li>
                                </ul>
                            </section>

                            <section id="zero-rated-vat">
                                <h2>Zero-Rated VAT (0%)</h2>
                                <p>
                                    Zero-rated items are subject to VAT regulations at a rate of 0%. Although VAT is applied, no charge is passed on to the consumer, and businesses may reclaim VAT on related expenses.&nbsp;
                                    For more details on reclaiming VAT for zero-rated items, please visit our&nbsp;
                                    <HashLink to="/reclaim-vat">Reclaim VAT</HashLink>&nbsp;page.
                                </p>
                                <p>
                                    Common examples of zero-rated items include:
                                </p>
                                <ul>
                                    <li>Children’s clothing and footwear</li>
                                    <li>Books, newspapers, and magazines</li>
                                </ul>
                            </section>

                            <section id="vat-exemptions">
                                <h2>VAT Exemptions</h2>
                                <p>
                                    In contrast to zero-rated items, VAT-exempt goods and services do not incur VAT, and businesses are unable to reclaim VAT on associated costs.
                                </p>
                                <p>
                                    Examples of VAT-exempt items include:
                                </p>
                                <ul>
                                    <li>Financial and insurance services</li>
                                    <li>Certain cultural services, such as museum admission</li>
                                </ul>
                            </section>

                            <section id="rates-comparison-table">
                                <h2>Comparison of VAT Rated Goods &amp; Services</h2>
                                <p>
                                    The table below categorizes various goods and services by their applicable VAT rate.
                                </p>
                                <div id="VATItemsTable">
                                    <table id="tblVATItems">
                                        <thead>
                                            <tr>
                                                <td>Standard Rate VAT Items</td>
                                                <td>Reduced Rate VAT Items</td>
                                                <td>Zero-Rated VAT Items</td>
                                                <td>VAT Exempt Items</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Electronics &amp; Appliances</td>
                                                <td>Domestic Fuel &amp; Power</td>
                                                <td>Fresh Produce, Cold Takeaway Food</td>
                                                <td>Financial and Insurance Services</td>
                                            </tr>
                                            <tr>
                                                <td>Clothing and Footwear (with specific exceptions)</td>
                                                <td>Children’s Car Seats</td>
                                                <td>Books, Newspapers</td>
                                                <td>Health Services by Registered Professionals (Doctors, Dentists, Opticians)</td>
                                            </tr>
                                            <tr>
                                                <td>Household Furniture &amp; Appliances</td>
                                                <td>Home Adaptations for Disabled Individuals</td>
                                                <td>Medical Equipment &amp; Aids</td>
                                                <td>Education</td>
                                            </tr>
                                            <tr>
                                                <td>Professional Services (e.g., legal, accounting)</td>
                                                <td>Residential Energy-Saving Materials</td>
                                                <td>Goods &amp; Services Exported Outside the EU</td>
                                                <td>Cultural Services, such as museum or gallery admission</td>
                                            </tr>
                                            <tr>
                                                <td>Fuel (excluding domestic fuel and power)</td>
                                                <td>Smoking Cessation Products (e.g., nicotine patches)</td>
                                                <td>Prescription Medications, Some Medical Equipment</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>

                            <section id="changes-to-vat-rates">
                                <h2>Changes to UK VAT Rates</h2>
                                <p>
                                    VAT rates in the United Kingdom are determined by legislation. Any amendments require parliamentary approval, ensuring transparency and accountability.
                                </p>
                                <p>
                                    Although the rates have remained relatively stable since their inception, it is imperative for businesses to stay informed about any legislative updates. For more detailed computations, refer to our&nbsp;
                                    <HashLink to="/uk-vat-formula">UK VAT Formula</HashLink>. Additionally, if you are interested in exploring alternative VAT schemes, please visit our&nbsp;
                                    <HashLink to="/flat-rate-vat">Flat Rate VAT</HashLink>&nbsp;page.
                                </p>
                            </section>

                            <section id="key-takeaways">
                                <h2>Key Takeaways</h2>
                                <p>
                                    Understanding the three primary VAT rates—20% standard, 5% reduced, and 0% zero-rated—is essential for accurate tax calculations.
                                </p>
                                <p>
                                    VAT exemptions differ from zero-rated items; VAT on exempt goods and services cannot be reclaimed.
                                </p>
                                <p>
                                    Staying informed about legislative changes is crucial to ensure compliance and avoid HMRC penalties.
                                </p>
                                <p>
                                    Proper categorization of goods and services supports accurate financial reporting and tax management.
                                </p>
                            </section>

                            <section id="conclusion">
                                <h2>Conclusion</h2>
                                <p>
                                    This article has provided an in-depth overview of the three main VAT rates in the UK while clarifying the differences between zero-rated and VAT-exempt items.
                                </p>
                                <p>
                                    By understanding these classifications and remaining vigilant to regulatory updates, businesses can maintain compliance and mitigate potential penalties.
                                </p>
                                <p>
                                    For more VAT-related articles and further resources, please visit our&nbsp;
                                    <HashLink to="/articles">Articles</HashLink>&nbsp;hub.
                                </p>
                            </section>
                        </div>

                        <div className="layout-col advert-col">
                            <Advert />
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="layout-row">
                        <div className="layout-col">
                            <Advert />
                        </div>
                    </div>
                    <div className="spacer"></div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default CurrentVATRateScreen
