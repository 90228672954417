import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import CookieBanner from '../components/CookieBanner';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Advert from '../components/Advert';

import './AboutUsScreen.css';

const AboutUsScreen = () => {
  const imagesDir = `/img`;

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Learn more about calcvat.co.uk, the UK's trusted VAT calculator. Discover our commitment to accuracy and our professional approach to VAT solutions for businesses and accounting professionals."
        />
      </Helmet>

      <CookieBanner />

      <Header />
      <main className="py-3">
        <Container id="AboutUsScreen" className="copy">
          <Row>
            <Col>
              <Advert />
            </Col>

            <Col xs={8}>
              {/* Page Heading */}
              <section id="heading">
                <h1 className="display-4 fw-normal">About Our Team</h1>
                <p className="centered-image">
                  <img src={`${imagesDir}/meet-the-team.jpg`} alt="Our Professional Team" />
                </p>
              </section>

              {/* Company History Section */}
              <section id="origin-story">
                <h2>The History of CalcVAT</h2>
                <p>
                  CalcVAT was established with the objective of delivering a reliable and accurate VAT calculation tool for businesses and accounting professionals across the UK. Recognizing the need for a platform that went beyond basic calculations, our founders set out to create a comprehensive resource that combines cutting-edge technology with expert financial guidance.
                </p>
                <p>
                  Since its inception, CalcVAT has continuously evolved to meet the demands of modern businesses, ensuring that users receive both robust calculation capabilities and professional insights into VAT compliance and management.
                </p>
              </section>

              {/* About Sharon Section */}
              <section id="about-sharon">
                <Container id="the-shazzle">
                  <Row>
                    <Col className="col-3">
                      <img id="shazzle-pic" className="circular" src={`${imagesDir}/moonkesh.jpg`} width="200" alt="Sharon Gillespie, Senior Financial Advisor" />
                    </Col>
                    <Col>
                      <h2>
                        Sharon Gillespie&nbsp;
                        <a href="https://www.linkedin.com/in/sharon-gillespie">
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </h2>
                      <h3>Senior Financial Advisor &amp; Company Director</h3>
                    </Col>
                  </Row>
                </Container>
                <p>
                  Sharon Gillespie is a distinguished accounting professional with over thirty years of experience in the industry. She holds a Bachelor’s degree in Accounting and is a Fellow of the Association of Chartered Certified Accountants as well as the Institute of Accounting Technicians Ireland.
                </p>
                <p>
                  In her role as a Company Director and Senior Financial Advisor for CalcVAT, Sharon is responsible for ensuring the platform’s financial integrity and compliance with industry standards.
                </p>
              </section>

              {/* About John Section */}
              <section id="about-john">
                <Container id="the-lapin">
                  <Row>
                    <Col className="col-3">
                      <img className="circular" src={`${imagesDir}/lappy.jpg`} width="200" alt="John McCullough, Head of Development" />
                    </Col>
                    <Col>
                      <h2>John McCullough</h2>
                      <h3>Developer, DramaLlama Ltd</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        John McCullough is an experienced software developer with a strong background in corporate software development. He holds a Bachelor’s degree in Computing Science and a Master’s in Games Programming. John has led the technical development of the CalcVAT platform, ensuring its robustness, security, and accuracy.
                      </p>
                      <p>
                        His approach is characterized by rigorous attention to detail and a commitment to excellence, qualities that are integral to delivering a reliable VAT calculator.
                      </p>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Col>

            <Col>
              <Advert />
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default AboutUsScreen;
