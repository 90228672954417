import React from 'react'
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/twitter'
import 'react-social-icons/facebook'
import 'react-social-icons/youtube'
import 'react-social-icons/instagram'
import 'react-social-icons/tiktok'

import Styles from './SocialMediaLinks.module.css'


const SocialMediaLinks = () => {

    const facebookLink  = process.env.REACT_APP_FB_LINK;
    const twitterLink   = process.env.REACT_APP_TWITTER_LINK;
    const youtubeLink   = process.env.REACT_APP_YOUTUBE_LINK;
    const instagramLink = process.env.REACT_APP_INSTAGRAM_LINK;
    //const pinterestLink = process.env.REACT_APP_PINTEREST_LINK;
    const extraProps    = { rel: 'noopener noreferrer external nofollow' };

    return (
    <>
    <section className={Styles.SocialMediaLinks}>

        <h2 className="mb-4">Shout Out</h2>
        <p>Please Give us a shout out on social media!</p>

        <div className={Styles.dFlex}>
            <SocialIcon label="X"  className={Styles.socialIcon} network="twitter"    {...extraProps} url={twitterLink} />
            <SocialIcon label="Fb" className={Styles.socialIcon} network="facebook"  {...extraProps} url={facebookLink} />
            <SocialIcon label="YT" className={Styles.socialIcon} network="youtube"   {...extraProps} url={youtubeLink} />
            <SocialIcon label="IG" className={Styles.socialIcon} network="instagram" {...extraProps} url={instagramLink} />
        </div>

    </section>
    </>
    )
}

export default SocialMediaLinks
