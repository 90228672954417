import React from 'react'

import frameStyles from './YoutubeVideoIFrame.module.css';


const YoutubeVideo = ({videoEmbedCode, videoTitle}) => {

    const videoSrc          = `https://www.youtube.com/embed/${videoEmbedCode}`
    const staticVideoCard   = `/img/calcvat-calculator-video-card.jpg`
    const autoplayLink      = `${videoSrc}?autoplay=1`
    // const playBtnSvg        = "<svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>"
    const srcDoc            = `
    <html>
        <head>
            <style>
                ${frameStyles.toString()}
            </style>
        </head>
        <body className='youtubeIframeContent'>
            <a href="${autoplayLink}">
                <img src="${staticVideoCard}" alt="${videoTitle}" />

            </a>
        </body>
    </html>`

    return (
    <>
    <div className="youtubeVideoIFrame">
        <iframe
        loading="lazy"
        width="720" height="440"
        title={videoTitle}
        src={videoSrc}
        srcDoc={srcDoc}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    </div>
    </>
    )
}

export default YoutubeVideo
