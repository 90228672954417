import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';

const FlatRateVATTable = () => {
    const [data, setData] = useState([]);
    const [showTable, setShowTable] = useState(true);

    useEffect(() => {
        axios.get('/data/rates.json')
            .then(response => setData(response.data))
            .catch(err => console.log(err));
    }, []);

    return (
        <div id="flat-rate-VAT-rates">
            <button onClick={() => setShowTable(!showTable)}>
                { showTable ? "hide" : "show" } table
            </button>

            {showTable && (
                <Table striped bordered hover className="table">
                    <thead>
                        <tr>
                            <th>Business Type</th>
                            <th>VAT Rate %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.label}</td>
                                <td>{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <div>
                <a href="https://www.gov.uk/vat-flat-rate-scheme/how-much-you-pay" target="_blank" rel="noopener noreferrer">
                    Source: HMRC VAT flat rates
                </a>
            </div>
        </div>
    );
};

export default FlatRateVATTable;
