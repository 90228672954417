import React from 'react';
import { Helmet } from 'react-helmet';

import Advert from '../components/Advert';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FlatRateVATTable from '../components/FlatRateVATTable';

import './FlatRateVATScreen.css';

const FlatRateVATScreen = () => {
    const imageDir = `/img`;

    return (
        <>
            <Helmet>
                <title>Flat Rate VAT Scheme 2025: Simplify VAT Calculations for Small Businesses | CalcVAT</title>
                <meta
                    name="description"
                    content="Explore the latest Flat Rate VAT Scheme for small businesses in the UK. Understand eligibility, benefits, drawbacks, and how to register for this HMRC scheme to simplify your VAT reporting."
                />
            </Helmet>
            <Header />
            <main>
                <div id="FlatRateVATScreen" className="copy wrapper">
                    <div className="layout-row">
                        <div className="layout-col advert-col">
                            <Advert />
                        </div>
                        <div className="layout-col content-col">
                            <h1 className="display-4">The New Flat Rate VAT Scheme</h1>
                            <div className="image-container">
                                <img src={`${imageDir}/taxation.jpg`} alt="The Flat Rate VAT Scheme" />
                            </div>

                            {/* Author Box */}
                            <div className="author-box">
                                <p>Written by Sharon Gillespie</p>
                                <p>Last updated: 12 Feb 2025</p>
                            </div>

                            {/* Table of Contents */}
                            <nav className="toc">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li><a href="#what-is-flat-rate">What is the Flat Rate VAT Scheme?</a></li>
                                    <li><a href="#what-is-vat">What Is VAT?</a></li>
                                    <li><a href="#introducing-flat-rate">Introducing the New Flat Rate VAT Scheme</a></li>
                                    <li><a href="#simple-example">A Simple Example</a></li>
                                    <li><a href="#eligibility">Eligibility for the Flat Rate VAT Scheme</a></li>
                                    <li><a href="#ineligibility">Criteria for Ineligibility</a></li>
                                    <li><a href="#pros-cons">Pros &amp; Cons</a></li>
                                    <li><a href="#key-takeaways">Key Takeaways</a></li>
                                    <li><a href="#in-summary">In Summary</a></li>
                                </ul>
                            </nav>

                            <section id="what-is-flat-rate">
                                <h2>What is the Flat Rate VAT Scheme?</h2>
                                <p>
                                    <strong>
                                        The Flat Rate VAT Scheme is a simplified method for small UK businesses to calculate and report VAT.
                                        Instead of tracking VAT on each transaction, eligible businesses pay a fixed percentage of their gross turnover (excluding VAT).
                                    </strong>
                                </p>
                            </section>

                            <section id="what-is-vat">
                                <h2>What Is VAT?</h2>
                                <p>
                                    For a detailed explanation, please visit our <a href="/what-is-vat">What is VAT?</a> page. In short, VAT (Value-Added Tax) is added to most purchases in the UK—from everyday items to services. The standard rate is 20%.
                                </p>
                                <p>
                                    As a VAT-registered business, you're responsible for collecting VAT from your customers and then subtracting any VAT you’ve paid on business expenses. VAT returns are usually filed every 3 to 4 months.
                                </p>
                            </section>

                            <section id="introducing-flat-rate">
                                <h2>Introducing the New Flat Rate VAT Scheme</h2>
                                <p>
                                    The new Flat Rate VAT Scheme simplifies VAT reporting for small businesses. Each business category is assigned a fixed percentage applied to your annual turnover. This method can save time and money by eliminating the need to record VAT on every individual sale and purchase.
                                </p>
                                <p>
                                    To see the applicable rates for different business categories, refer to the table below:
                                </p>
                                <FlatRateVATTable />
                            </section>

                            <section id="simple-example">
                                <h3>A Simple Example</h3>
                                <div className="example-box">
                                    <p>
                                        For example, if a transport company has a flat rate of 10% and earns an annual turnover of £90,000, the VAT owed would be calculated as:
                                    </p>
                                    <p>
                                        <strong>£90,000 x 10% = £9,000</strong>
                                    </p>
                                    <p>
                                        With HMRC's 1% discount for new VAT registrants, the effective rate becomes 9%, reducing the VAT payable to:
                                    </p>
                                    <p>
                                        <strong>£90,000 x 9% = £8,100</strong>
                                    </p>
                                    <p>
                                        This results in a saving of £900.
                                    </p>
                                </div>
                            </section>

                            <section id="eligibility">
                                <h2>Eligibility for the Flat Rate VAT Scheme</h2>
                                <p>
                                    To be eligible, your business must:
                                </p>
                                <ul>
                                    <li>Be VAT registered</li>
                                    <li>Have a predicted annual turnover of less than £150,000 (excluding VAT)</li>
                                </ul>
                            </section>

                            <section id="ineligibility">
                                <h2>Criteria for Ineligibility</h2>
                                <p>
                                    Your business may not qualify if you have:
                                </p>
                                <ul>
                                    <li>Left the scheme in the last 12 months</li>
                                    <li>Committed a tax-related criminal offence in the last 12 months</li>
                                    <li>Joined or are eligible to join a VAT group in the last 24 months</li>
                                    <li>Registered a business division for VAT in the last 24 months</li>
                                    <li>Strong financial, economic, or organisational links to another business</li>
                                    <li>Already joined a margin or capital goods VAT scheme</li>
                                </ul>
                            </section>

                            <section id="pros-cons">
                                <h2>Pros &amp; Cons of the Flat Rate VAT Scheme</h2>
                                <h3>Pros</h3>
                                <ul>
                                    <li>Easy registration process</li>
                                    <li>Flexibility to withdraw from the scheme at any time</li>
                                    <li>Retain the difference between the standard 20% VAT charged and your flat rate</li>
                                    <li>Simplified record keeping</li>
                                    <li>Potential cost savings, including a 1% discount for new businesses</li>
                                </ul>
                                <h3>Cons</h3>
                                <ul>
                                    <li>Inability to reclaim VAT on most purchases (except for qualifying capital assets over £2,000)</li>
                                    <li>Limited cost businesses may incur a higher flat rate (e.g., 16.5%)</li>
                                    <li>Complexity in business classification</li>
                                    <li>Not ideal for businesses with high purchase volumes</li>
                                </ul>
                            </section>

                            <section id="key-takeaways">
                                <h2>Key Takeaways</h2>
                                <p>
                                    <strong>- The Flat Rate VAT Scheme simplifies VAT reporting by applying a fixed percentage to turnover.</strong>
                                </p>
                                <p>
                                    - Designed for VAT-registered small businesses with an annual turnover under £150,000.
                                </p>
                                <p>
                                    - Offers significant time and cost savings, particularly with a 1% discount available for new businesses.
                                </p>
                                <p>
                                    - Not suitable for businesses with high purchase volumes or those considered limited cost businesses.
                                </p>
                            </section>

                            <section id="in-summary">
                                <h2>In Summary</h2>
                                <p>
                                    The new Flat Rate VAT Scheme provides small businesses with a streamlined approach to handling VAT, potentially reducing administrative burdens and saving money. However, it is crucial to evaluate your business’s specific needs and consult with an accountant before opting into the scheme.
                                </p>
                                <p>
                                    For further assistance, try our <a href="/vat-calculator">VAT Calculator</a> and explore additional VAT resources in our <a href="/articles">Articles</a> section.
                                </p>
                                <p>
                                    If you wish to register for the scheme, please visit our <a href="/register-for-vat-uk">Register for VAT</a> page.
                                </p>
                                <p>
                                    Indeed, there is much to consider. Business owners must carefully consider whether the flat rate VAT scheme is suitable for their needs, since some limitations and restrictions may make it less beneficial for some businesses. It is a good idea to discuss the pros and cons of the flat rate VAT scheme for your business with your accountant. You may sign up to the scheme by <a href="https://www.gov.uk/vat-flat-rate-scheme/join-or-leave-the-scheme" target="_blank" rel="noopener noreferrer">registering here</a>, although do discuss it first with your accountant, as any decent accountant will be happy to perform this step on your behalf.
                                </p>
                            </section>
                        </div>
                        <div className="layout-col advert-col">
                            <Advert />
                        </div>
                    </div>

                    <div className="spacer"></div>
                    <div className="layout-row">
                        <div className="layout-col">
                            <div className="display-ad">
                                <Advert />
                            </div>
                        </div>
                    </div>
                    <div className="spacer"></div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default FlatRateVATScreen;
