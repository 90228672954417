import React, { /*useState,*/ useEffect } from 'react'
//import axios from 'axios'

import './PartnerIndex.css'
import data from './countiesByCountry.json'


const PartnerIndex = () => {

    //const [countryData, setCountryData] = useState([]);
    //const [loading, setLoading]         = useState(false);
    //const [error, setError]             = useState(null);


    useEffect (() => {
        // const url
        //     = `${process.env.REACT_APP_API_ENDPOINT}/uk/getCountiesByCountry`;

        // axios.get(url)
        //     .then (response => {
        //         return response.data;

        //     })
        //     .then (data => {
        //         setCountryData(data);
        //         console.log(JSON.stringify(data));
        //         setLoading(false);
        //     })
        //     .catch(error => {
        //         setError(error);
        //         setLoading(false);
        //     })
    }, [])


    return (
    <>
        <div className="countryData">

        {

            data && (

                data.map((item, index) => (

                    <div key={index} className="country">

                        <h2>{item.countryName}</h2>

                        <ul className="counties">
                            {
                                item.counties.map((county, i) => (
                                    <li key={i}><a href={`/partners/${item.countryName}/${county}`}>{county}</a></li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            )

        }
        </div>
    </>
    )
}

export default PartnerIndex


