import React            from 'react'
import SiteLogo         from './SiteLogo'
import CompanyAddress   from './CompanyAddress'
import SocialMediaLinks from './SocialMediaLinks'

import './Footer.css'

const Footer = () => {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-section left-section">
              <h2 className="footer-title">CalcVAT.co.uk</h2>
              <p className="footer-text">
                We&apos;d love to hear your feedback – please get in touch.
              </p>
            </div>

            <div className="footer-section logo-section" id="logoContainer">
              <SiteLogo />
            </div>

            <div className="footer-section address-section" id="get-in-touch">
              <CompanyAddress />
            </div>

            <div className="footer-section social-section">
              <SocialMediaLinks />
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="footer-bottom-container">
            <div className="footer-bottom-left">
              {/* Additional content can be placed here if needed */}
            </div>
            <div className="footer-bottom-right">
              &copy; 2023-{currentYear} <a href="https://dramallama.ltd">Dramallama ltd</a>, all rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer
