import React from 'react'
import { HashLink } from 'react-router-hash-link'

import './SiteLogo.css'



const SiteLogo = () => {
    return (
    <>
    <section id="SiteLogo">
        <HashLink smooth to="/vat-calculator#CalculatorScreen">
            <img src='/img/calcvat-calculator-25.png' alt='UK VAT Calculator' />
        </HashLink>
    </section>
    </>
    )
}

export default SiteLogo
