import React from 'react';

const CompanyAddress = () => {
  const address =
    process.env.REACT_APP_COMPANY_ADDRESS ||
    "Dramallama Limited, Unit 55 Mallusk Enterprise Park, Newtownabbey, Co. Antrim, BT36 4GN, UK";

  const emailAddress =
    process.env.REACT_APP_COMPANY_EMAIL || "enquiries [at] dramallama [dot] ltd";
  const telephone = process.env.REACT_APP_COMPANY_TELEPHONE;

  return (
    <>
      <h2>Get In Touch</h2>

      <ul id="company-address">
        {address.split(",").map((ln, idx) => (
          <li key={idx}>{ln}</li>
        ))}
      </ul>

      <p id="email-address">{emailAddress}</p>

      {telephone && <p id="telephone">{telephone}</p>}
    </>
  );
};

export default CompanyAddress;
